import * as React from "react"
import { Link, GatsbyLinkProps, useStaticQuery, graphql } from "gatsby"
import { PALETTE } from "./colors"
import Img from "gatsby-image"

const CustomLink = ({ to, children }: GatsbyLinkProps<any>) => {
  return (
    <Link
      to={to}
      className="h-full flex px-8 items-center opacity-50 hover:opacity-100"
      activeClassName="opacity-100"
    >
      {children}
    </Link>
  )
}

type Props = {
  style?: React.CSSProperties
}

export const Header = ({ style }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      ...LOGO_QUERY
    }
  `)
  console.log(data)
  return (
    <div
      className="w-full  flex flex-row items-center"
      style={{ height: 72, background: PALETTE.primary.opaque, ...style }}
    >
      <Img
        fluid={data.file.childImageSharp.fluid}
        style={{ width: 50, height: "100%" }}
      />
      <CustomLink to="/">Home</CustomLink>
      <CustomLink to="/blog">Adventures</CustomLink>
      <CustomLink to="/about">About</CustomLink>
    </div>
  )
}
