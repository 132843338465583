import * as React from "react"
import { PALETTE } from "./colors"

export const BottomMatter = () => {
  return (
    <div style={{ background: PALETTE.primary.transparent }}>
      Woah, you went all this way. Thanks!
    </div>
  )
}
