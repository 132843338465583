import * as React from "react"
import { useMedia } from "web-api-hooks"

export const FOOTER_HEIGHT = 20
export const FOOTER_HEIGHT_MOBILE = 44
export const HEADER_HEIGHT = 72

const getNavigationHeightMobile = () => {
  return HEADER_HEIGHT + FOOTER_HEIGHT_MOBILE
}

const getNavigationHeight = () => {
  return HEADER_HEIGHT + FOOTER_HEIGHT
}

export const useNavigationHeight = () => {
  const isMobile = useMedia("(max-width: 768px)")
  if (isMobile) {
    return getNavigationHeightMobile()
  }
  return getNavigationHeight()
}

export const useMaxItemHeight = () => {
  const navigationHeight = useNavigationHeight()
  return `calc(100vh - ${navigationHeight}px)`
}
