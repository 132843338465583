import * as React from "react"
import { ScrollProgress } from "./scroll-progress"
import { css } from "@emotion/core"

export const ScrollHelper = () => {
  return (
    <>
      <button
        onClick={() => {
          window.document.body.parentElement.scrollTop = 0
        }}
        className="fixed bottom-0 right-0 z-10"
        css={{
          width: 60,
          height: 60,
          background: "white",
        }}
      >
        ^
      </button>
      <ScrollProgress />
      <button
        className="transform rotate-180 fixed top-0 right-0 z-10"
        onClick={() => {
          window.document.body.parentElement.scrollTop =
            window.document.body.parentElement.scrollHeight
        }}
        css={{
          width: 60,
          height: 60,
          background: "white",
        }}
      >
        <span>^</span>
      </button>
    </>
  )
}
