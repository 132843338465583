const COLORS = {
  primary: `247, 249, 249`,
}

export const PALETTE = {
  primary: {
    plain: COLORS.primary,
    opaque: `rgba(${COLORS.primary}, 1)`,
    transparent: `rgba(${COLORS.primary}, .8)`,
  },
}
