import * as React from "react"
import { Header } from "./header"
import { Content } from "./content"
import { BottomMatter } from "./bottom-matter"
import { ScrollProgress } from "./scroll-progress"
import { HEADER_HEIGHT, FOOTER_HEIGHT } from "./hooks"
import { ScrollHelper } from "./scroll-helper"
import { jsx } from "@emotion/core"

type Props = {
  children: React.ReactElement | string
}

export const Layout = ({ children }: Props) => {
  return (
    <div
      css={{
        paddingRight: 60,
      }}
    >
      <Header style={{ height: HEADER_HEIGHT }} />
      <Content>
        <>
          {children}
          <BottomMatter />
        </>
      </Content>
      <ScrollHelper />
    </div>
  )
}
