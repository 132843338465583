import * as React from "react"
import { PALETTE } from "./colors"

let htmlReference
if (typeof window !== `undefined`) {
  htmlReference = document.querySelector("html")
}

const getProgressFromMouseEvent = (e: MouseEvent | React.TouchList[0]) => {
  return (
    ((lastMouseEvent.clientY - 60) / (window.innerHeight - 120)) *
    (htmlReference.scrollHeight - window.innerHeight)
  )
}

const getProgress = () => {
  if (htmlReference === undefined) {
    return 0
  }
  return (
    (htmlReference.scrollTop /
      (htmlReference.scrollHeight - window.innerHeight)) *
    100
  )
}

const startUpdatingProgress = ({
  setProgress,
}: {
  setProgress: React.Dispatch<React.SetStateAction<number>>
}) => {
  animationFrameReferenceNumber = window.requestAnimationFrame(() => {
    setProgress(getProgress())
    startUpdatingProgress({ setProgress })
  })
}

const startAffectingScroll = ({
  setProgress,
}: {
  setProgress: React.Dispatch<React.SetStateAction<number>>
}) => {
  animationFrameReferenceNumber2 = window.requestAnimationFrame(() => {
    if (lastMouseEvent !== undefined) {
      htmlReference.scrollTop = getProgressFromMouseEvent(lastMouseEvent)
    }
    startAffectingScroll({ setProgress })
  })
}

let animationFrameReferenceNumber = undefined as undefined | number
let animationFrameReferenceNumber2 = undefined as undefined | number
let lastMouseEvent = undefined as undefined | MouseEvent | React.TouchList[0]

type Props = {
  className?: string
  style?: React.CSSProperties
}

export const ScrollProgress = ({ style, className }: Props) => {
  const [progress, setProgress] = React.useState(getProgress())
  const [grabbing, setGrabbing] = React.useState(false)
  React.useEffect(() => {
    const mouseMoveCallback = e => {
      lastMouseEvent = e
    }
    const mouseUpCallback = e => {
      setGrabbing(false)
    }
    const mouseLeaveCallback = e => {
      console.log("left!")
      setGrabbing(false)
    }
    htmlReference.addEventListener("mousemove", mouseMoveCallback)
    htmlReference.addEventListener("mouseup", mouseUpCallback)
    htmlReference.addEventListener("mouseleave", mouseLeaveCallback)
    return () => {
      htmlReference.removeEventListener("mousemove", mouseMoveCallback)
      htmlReference.removeEventListener("mouseup", mouseUpCallback)
      htmlReference.removeEventListener("mouseleave", mouseLeaveCallback)
    }
  }, [])
  React.useEffect(() => {
    startUpdatingProgress({ setProgress })
    return () => {
      window.cancelAnimationFrame(animationFrameReferenceNumber)
    }
  }, [])
  React.useEffect(() => {
    if (grabbing) {
      startAffectingScroll({ setProgress })
    }
    return () => {
      window.cancelAnimationFrame(animationFrameReferenceNumber2)
    }
  }, [grabbing])
  return (
    <div
      className={`fixed ${className}`}
      style={{
        right: 0,
        top: 60,
        background: PALETTE.primary.opaque,
        cursor: grabbing ? "grabbing" : "grab",
        height: "calc(100vh - 120px)",
        width: 60,
        opacity: 0.8,
        touchAction: "none",
        ...style,
      }}
      onClick={e => {
        htmlReference.scrollTop = getProgressFromMouseEvent(e)
      }}
      onTouchStart={e => {
        e.preventDefault()
        setGrabbing(true)
      }}
      onMouseDown={e => {
        e.preventDefault()
        setGrabbing(true)
      }}
      onMouseUp={e => {
        setGrabbing(false)
      }}
      onTouchMove={e => {
        e.preventDefault()
        lastMouseEvent = e.changedTouches[0]
      }}
      onTouchCancel={e => {
        setGrabbing(false)
      }}
      onTouchEnd={e => {
        setGrabbing(false)
      }}
    >
      <div
        className="absolute left-0 top-0"
        style={{ height: `${progress}%`, width: "100%", background: "grey" }}
      ></div>
    </div>
  )
}
